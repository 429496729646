.left_nav {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  /* width: 240px; */
  width: 260px;
  box-shadow: 0 0 6px #ccc;
  padding-top: 40px;
}
.nav_item {
  display: flex;
  padding: 2px 10px 2px 15px;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1.05412px;
  color: rgba(0, 0, 0, 0.5);
  font-family: 'PlusJakartaSans-Regular';
  white-space: nowrap;
}
.active {
  color: #1a7cfa;
  background-color: #e8f1fe;
  border-right: 3px solid;
  border-color: #1a7cfa;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.05412px;
  font-family: 'PlusJakartaSans-Regular';
}
.nav_label {
  margin-left: 10px;
}


@media screen and (max-width:767px) {
  .active {
      border-right: 0px;
      border-color: transparent;
      background-color: transparent;
  }
}