$default-white: #ffffff;
$default-black: #000000;
$jumna_theme_clr: #1a7cfa;
$bgColor: #f4f4f4;
$green: #2dc093;

//Font Family
$notosansRegular: 'Notosans-regular';
$notosansBold: 'NotoSans-Bold';
$plusJakartaSans-Light: 'PlusJakartaSans-Light';
$plusJakartaSans-Medium: 'PlusJakartaSans-Medium';
$plusJakartaSans-Regular: 'PlusJakartaSans-Regular';
$plusJakartaSans-SemiBold: 'PlusJakartaSans-SemiBold';
$plusJakartaSans-Bold: 'PlusJakartaSans-Bold';

$size-minimum: 12px;

@font-face {
  font-family: 'Righteous';
  src: local('Righteous'), url(./fonts/Righteous-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}
@font-face {
  font-family: 'Notosans-regular';
  src: local('Noto_Sans'), url(./fonts/Noto_Sans/NotoSans-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}
@font-face {
  font-family: 'NotoSans-Bold';
  src: local('NotoSans-Bold'), url(./fonts/Noto_Sans/NotoSans-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: bold;
  font-display: fallback;
}
@font-face {
  font-family: 'PlusJakartaSans-Regular';
  src: local('PlusJakartaSans-Regular'),
    url(./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: auto;
}
@font-face {
  font-family: 'PlusJakartaSans-Medium';
  src: local('PlusJakartaSans-Medium'),
    url(./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: auto;
}
@font-face {
  font-family: 'PlusJakartaSans-Bold';
  src: local('PlusJakartaSans-Bold'),
    url(./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: auto;
}
@font-face {
  font-family: 'PlusJakartaSans-Light';
  src: local('PlusJakartaSans-Light'),
    url(./fonts/Plus_Jakarta_Sans/PlusJakartaSans-ExtraLight.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
  font-display: auto;
}
@font-face {
  font-family: 'PlusJakartaSans-SemiBold';
  src: local('PlusJakartaSans-Light'),
    url(./fonts/Plus_Jakarta_Sans/PlusJakartaSans-SemiBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
  font-display: auto;
}

body,
div,
p,
select,
button,
input,
table,
a,
span {
  font-family: $plusJakartaSans-Regular;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.mobileHeader {
  display: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex_row {
  display: flex;
  flex-direction: row;
}
.flex_column {
  display: flex;
  flex-direction: column;
}
.align_center {
  align-items: center;
}
.justify_center {
  justify-content: center;
}
.justify_s_between {
  justify-content: space-between;
}
.justify_s_around {
  justify-content: space-around;
}
.main_container {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: #f0f5fd;
  background-color: #f4f4f4;
  width: 100%;
}
.backNavContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pendingStatementLink {
  font-size: 14px;
  font-family: $plusJakartaSans-SemiBold;
  letter-spacing: 0.5px;
  margin-left: 16px;
}
.totalPendingBalance{
  font-size: 14px;
  font-family: $plusJakartaSans-SemiBold;
  letter-spacing: 0.5px;
  margin-left: 16px;
  color:$jumna_theme_clr
}
.totalAccountBalance{
  font-size: 14px;
  font-family: $plusJakartaSans-SemiBold;
  letter-spacing: 0.5px;
  margin-left: 16px;
  color:$jumna_theme_clr;
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: right;
}
.form_container {
  background-color: $default-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 450px;
  margin: auto;
  padding: 40px;
  box-shadow: 0px 0px 6px #ccc;
}
.margin_10_30 {
  margin: 10px 30px;
}
.input-wrapper {
  width: 100%;
  margin: 15px 0;
}
.forgotPwd {
  float: right;
  font-size: $size-minimum;
  margin: 0;
  margin-top: 4px;
  cursor: pointer;
  color: $jumna_theme_clr;
}

.dontHaveAcc {
  margin: 0;
  font-size: $size-minimum;
  font-family: $plusJakartaSans-Regular;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.signupBtn {
  color: $jumna_theme_clr;
  cursor: pointer;
  margin-left: 3px;
  font-family: $plusJakartaSans-Bold;
}

.login-btn {
  .MuiButton-label {
    font-family: $plusJakartaSans-Bold;
    text-transform: none;
  }
}
.btn-container {
  margin-top: 30px;
  width: 100%;
  text-align: right;
}
.signInHeadig {
  margin-top: 10px;
  font-size: 18px;
  font-family: $plusJakartaSans-Bold;
}
.padding_left_15 {
  padding-left: 15px;
}
.margin_bottom_30 {
  margin-bottom: 30px;
}
.padding_right_15 {
  padding-right: 15px;
}
.textWrapper {
  padding: 40px;
}
.side_bar {
  width: 200px;
  background-color: #61dafb;
}
.home {
  height: 100vh;
}
.minHeight {
  min-height: 100vh;
}
.textTransform {
  text-transform: capitalize;
}
.route-body {
  width: 100%;
  margin-left: 50px;
  margin-top: 50px;
  margin-right: 50px;
  overflow-x: hidden;
  background-color: $default-white;
}
.main-body {
  width: 100%;
  margin: 50px;
  overflow-x: hidden;
  // overflow: auto;
  // background-color: $default-white;
  // margin-bottom: 30px;
}
.app_header {
  //height: 70px;
  background-color: $default-white;
}
.MuiAppBar-colorPrimary.app_header {
  background-color: $default-white;
}
.container {
  margin: 20px 30px;
  width: 90%;
}
.loan_container {
  margin: 20px auto;
  width: 94%;
}
.cashAccTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.loanWrapper {
  width: 100%;
  overflow-x: hidden;
}
.bgThemeColor {
  background-color: $bgColor;
  margin-top: 68px;
}
.pendingStatementsTotalContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.umHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 48px;
  .MuiButton-containedPrimary:hover,
  .MuiButton-containedPrimary {
    background-color: $jumna_theme_clr;
  }
  .MuiButton-label {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.7px;
    color: $default-white;
    opacity: 0.9;
    text-transform: none;
  }
}
.umTableSec {
  margin-top: 20px;
}
.loanLogSec {
  padding: 30px;
}
.tableWidth {
  .MuiTable-root {
    width: 94%;
    margin: 40px auto;
  }
  .MuiTableHead-root {
    background-color: #f4f9ff;
    border-top: 0.5px solid #ccc;
  }
  .MuiButton-outlinedPrimary {
    color: #1055cc;
    border: 1px solid #1055cc;
  }
  .MuiButton-root {
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.5px;
    color: #1055cc;
    font-family: $plusJakartaSans-Regular;
  }
}
.merchantTable {
  .MuiTableHead-root {
    background-color: #f4f9ff;
  }
  .MuiTable-root {
    margin-top: 2px;
  }
  .MuiTableRow-root {
    cursor: pointer;
  }
}
.actionLink {
  .MuiButton-label {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    color: $jumna_theme_clr;
    font-family: $plusJakartaSans-SemiBold;
  }
  .MuiButton-text {
    padding: 6px;
  }
  .MuiTouchRipple-root {
    display: none;
  }
  .MuiButton-root {
    text-transform: none;
  }
  .MuiButton-root:hover {
    background-color: transparent;
  }
}
.detailsBtn {
  .MuiButton-outlined {
    border: 0.5px solid #333F48;
    padding: 5px 10px;
  }
  .MuiButton-root {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: #242F3E;
    border-radius: 0px;
  }
  .MuiButton-label {
    font-family: $plusJakartaSans-SemiBold !important;
  }
  .MuiTouchRipple-root {
    display: none;
  }
  .MuiButton-root {
    text-transform: none;
  }
  .MuiButton-root:hover {
    background-color: transparent;
  }
}
.documentTableBodyText {
  .MuiTableCell-root {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #0E0E0E;
    font-family: $plusJakartaSans-Regular;
  }
}
.previewLink {
  .MuiButton-root {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: $jumna_theme_clr;
    text-transform: none;
  }
  .MuiButton-label {
    font-family: $plusJakartaSans-Regular;
  }
  .MuiButton-text {
    padding: 6px 0px;
  }
}
.noDocText {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paginationSec {
  display: flex;
  justify-content: center;
  margin: 20px;
}
.btn_approve {
  margin-bottom: 5px;
  .MuiButton-outlined {
    border: 0.5px solid #46ae24;
    color: #46ae24;
    padding: 2px 6px;
    border-radius: 0px;
  }
  .MuiButton-root:hover {
    background-color: $default-white;
  }
  .MuiButton-label {
    font-weight: 600;
    font-size: 11px;
    letter-spacing: 0.5px;
    color: #46ae24;
    text-transform: none;
  }
}
.btn_reject {
  //margin-bottom: 3px;
  .MuiButton-outlined {
    border: 0.5px solid #bd3c39;
    color: #bd3c39;
    padding: 2px 6px;
    border-radius: 0px;
  }
  .MuiButton-root:hover {
    background-color: $default-white;
  }
  .MuiButton-label {
    font-weight: 600;
    font-size: 11px;
    letter-spacing: 0.5px;
    color: #bd3c39;
    text-transform: none;
  }
}
.enigmaCard {
  padding: 30px;
  margin-top: 30px;
  // height: 80%;
  // overflow-y: scroll;
}
.textBold {
  .MuiTableCell-head {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #0e0e0e;
    font-family: $plusJakartaSans-Regular;
  }
}
.textNormal {
  .MuiTableCell-root {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #0e0e0e;
    font-family: $plusJakartaSans-Regular;
  }
}
.switchDisable {
  .MuiSwitch-sizeSmall {
    width: 32px;
    height: 26px;
  }
  .MuiSwitch-sizeSmall .MuiSwitch-thumb {
    width: 7px;
    height: 7px;
    margin-top: 5.5px;
    margin-left: 5px !important;
    background-color: $default-white;
  }
}
/* styles for active switch*/
.switchEnable {
  .MuiSwitch-sizeSmall {
    width: 32px;
    height: 26px;
  }
  .MuiSwitch-sizeSmall .MuiSwitch-thumb {
    width: 7px;
    height: 7px;
    margin-top: 5.5px;
    margin-left: -4px;
    background-color: $default-white;
  }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: $green;
  }
}
.enigmaSec {
  height: 70vh;
  overflow-y: scroll;
}
.usersHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .MuiButton-containedPrimary:hover,
  .MuiButton-containedPrimary {
    background-color: $jumna_theme_clr;
  }
  .MuiButton-label {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.7px;
    color: $default-white;
    opacity: 0.9;
    text-transform: none;
  }
}
.firstSec {
  display: flex;
  align-items: center;
  .MuiTypography-body1 {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1.35529px;
    text-transform: uppercase;
    color: #000000;
    font-family: $plusJakartaSans-SemiBold;
  }
}
.backImg {
  height: 15px;
  margin-right: 10px;
  cursor: pointer;
}
// .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
//   width: 450px !important;
// }
.modalPopup {
  padding: 20px;
  .MuiButton-containedPrimary {
    background-color: $jumna_theme_clr;
    width: 200px;
    height: 44px;
    margin-right: 14px;
    margin-top: 10px;
  }
  .MuiButton-containedPrimary:hover {
    background-color: $jumna_theme_clr;
  }
  // .MuiFormControl-marginDense {
  //   margin-top: 18px;
  // }
  .MuiTypography-h6 {
    font-weight: 700;
    font-size: 21px;
    letter-spacing: 0.5px;
    color: #000000;
    font-family: $plusJakartaSans-Bold;
  }
  .MuiTypography-body1 {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: #282828;
    font-family: $plusJakartaSans-Regular;
  }
  .MuiTypography-root.MuiTypography-body1.MuiTypography-colorTextSecondary {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.6px;
    color: #282828;
    font-family: $plusJakartaSans-Regular;
  }
  .MuiRadio-colorPrimary.Mui-checked {
    color: $jumna_theme_clr;
  }
  .MuiFormLabel-root {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.9px;
    color: #282828;
    opacity: 0.8;
    font-family: $plusJakartaSans-Regular;
  }
  .MuiInputBase-inputMarginDense {
    padding-top: 8px;
  }
  .MuiInputBase-input {
    font-size: 18px;
    font-family: $plusJakartaSans-Regular;
    letter-spacing: 0.9px;
    color: #282828;
  }
}
.margin_top_10 {
  margin-top: 10px;
}
.headerSec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 40px;
  background-color: $default-white;
  height: 68px !important;
}
.logout {
  font-size: 13px;
  color: $default-black;
  cursor: pointer;
  font-family: $plusJakartaSans-SemiBold;
  margin: 0px 15px;
  display: flex;
  align-items: center;
}
.logoutImg {
  margin-right: 8px;
}
.cursor {
  cursor: pointer;
}
.loanLogTables {
  margin-top: 20px;
  .MuiTableCell-head {
    font-family: $plusJakartaSans-Bold;
  }
  .MuiTableCell-root.MuiTableCell-body {
    font-family: $plusJakartaSans-Regular;
    max-width: 155px;
  }
  .MuiTableCell-root {
    padding: 16px 10px;
  }
}
//new sign in
.FormTxt {
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiButton-label {
    text-transform: none;
  }
  .MuiInputBase-root {
    font-size: 14px;
    letter-spacing: 0.7px;
    color: #282828;
    font-family: $plusJakartaSans-Regular;
  }
  .MuiFormLabel-root {
    font-size: 14px;
    letter-spacing: 0.7px;
    color: rgba(40, 40, 40, 0.5);
    mix-blend-mode: normal;
    //opacity: 0.5;
    font-family: $plusJakartaSans-Regular;
  }
  .MuiTypography-body1 {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.7px;
    color: $jumna_theme_clr;
    font-family: $plusJakartaSans-Regular;
    text-align: right;
    margin-top: 10px;
  }
  .MuiInputBase-input {
    font-size: 18px;
    color: #282828;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: #000000;
    opacity: 0.5;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiInput-underline:after {
      border-bottom: 2px solid $jumna_theme_clr;
  }
  .MuiFormLabel-root.Mui-focused {
      color: $jumna_theme_clr;
  }
}
.passwordTxt_inactive {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #828282;
  font-family: $plusJakartaSans-Regular;
  margin-right: 20px;
}
.passwordTxt_active {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #2f363f;
  font-family: $plusJakartaSans-Regular;
  margin-right: 20px;
}
.otpTxt_active {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #2f363f;
  font-family: $plusJakartaSans-Regular;
}
.otpTxt_inactive {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #828282;
  font-family: $plusJakartaSans-Regular;
}
.headingText {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.855294px;
  color: #000000;
  margin-bottom: 20px;
  font-family: $plusJakartaSans-Bold;
}

.unplank_logo {
  width: 172px;
  cursor: pointer;
}

.createMerchant {
  .MuiFormControl-root {
    width: 100%;
  }
}

.otpSec {
  width: 90%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
 .MuiFormControl-root {
      width: 45px !important;
      margin-left: 5px;
      margin-right: 5px;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiInput-underline:after {
      border-bottom: 2px solid $jumna_theme_clr;
  }
}

.otpTextField .MuiInputBase-input {
  text-align: center;
}

.textUnderline {
  color: $jumna_theme_clr;
}
.termsOfUseText {
  text-align: justify;
}
.lenderBannerSec {
  width: 100%;
  height: 59px;
  background: #F4F9FF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  box-sizing: border-box;
  overflow: hidden;
}
.lenderWrapper {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  opacity: 0.8;
  p {
    font-family: $plusJakartaSans-Regular;
    span {
      font-family: $plusJakartaSans-Bold;
    }
  }
}
.margin_bottom_40 {
   margin-bottom: 40px;
}
.closeImg {
  float: right;
}
.menuIcon {
  width: 18px;
  height: 18px;
}
.loanLogSec {
  .MuiTypography-body1 {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #0E0E0E;
    font-family: $plusJakartaSans-Regular;
  }
  .MuiRadio-colorPrimary.Mui-checked {
    color: $jumna_theme_clr;
  }
}
.last_update_title {
  h2 {
      font-family: "PlusJakartaSans-Regular";
      font-size: 16px;
      line-height: 48px;
      letter-spacing: -0.02em;
      color: #282828;
      opacity: 0.8;
      font-weight: 400;
  }
}
.policy_detail_sec {
  p {
      font-family: "PlusJakartaSans-Regular";
      font-size: 16px;
      line-height: 38px;
      letter-spacing: -0.02em;
      color: #282828;
      margin-top: 0px;
      margin-bottom: 0px;
  }
}
.link_text {
  color: #1a7cfa;
  text-decoration: underline;
  cursor: pointer;
}
.sub_link_text {
  padding-left: 10px;
}
.privacy_policy_info {
  h2 {
      font-family: "PlusJakartaSans-SemiBold";
      font-weight: 600;
      font-size: 24px;
      line-height: 38px;
      letter-spacing: -0.02em;
      color: #282828;
      margin-bottom: 0px;
  }
  p {
      font-family: "PlusJakartaSans-Regular";
      font-size: 16px;
      line-height: 38px;
      letter-spacing: -0.02em;
      color: #282828;
      margin-top: 0px;
      margin-bottom: 0px;
  }
}
.documentPopUp {
  .MuiDialog-paper {
    padding: 16px 20px;
  }
  .MuiDialogContent-root {
    overflow-y: hidden;
  }
}
.pdfPreview {
  height: 600px;
  width: 550px;
}
.imgPreview {
  width: 100%;
  // height: 400px;
  object-fit: cover;
}
.actionPopUp {
  .MuiDialog-paper {
      padding: 16px 20px;
  }
  .MuiButton-label {
      text-transform: none;
  }
  .MuiTypography-h6 {
      font-size: 21px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.5px;
      color: $default-black;
      font-weight: 700;
  }
  .MuiButton-containedPrimary {
      background: $jumna_theme_clr;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0605059);
      border-radius: 2px;
      padding: 10px;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.8px;
      color: #FFFFFF;
      font-family: $plusJakartaSans-Regular;
      font-weight: 700;
  }
  .MuiButton-containedPrimary:hover {
    background-color: $jumna_theme_clr;
  }
  .MuiButton-outlinedPrimary {
      background: $default-white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0605059);
      color: $jumna_theme_clr;
      border-radius: 2px;
      padding: 10px;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.8px;
      font-family: $plusJakartaSans-Regular;
      font-weight: 700;
  }
  .MuiDialogTitle-root {
      padding: 40px 60px;
  }
  .MuiDialogActions-root {
      padding: 10px 60px 40px;
  }
}
.close_img {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  // body{
  //   overflow-x: hidden;
  // }
  .main_container {
    background-color: $default-white;
  }
  .webHeader {
    display: none;
  }
  .form_container {
    padding: 10px;
    box-shadow: none;
  }
  .mobileHeader {
    display: block;
    .menuOverlay {
        position: absolute;
        top: 68px;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        background-color: $default-white;
    }
    .menuNavItems {
        padding: 20px 60px;
    }
    .mobileMenuItem {
        margin: 30px 20px;
        color: black;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        font-family: "PlusJakartaSans-Regular";
        a {
            color: black;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            font-family: "PlusJakartaSans-Regular";
            text-decoration: none;
        }
    }
  }
  .left_nav {
    display: none;
  }
  .unplankLogo {
    width: 120px;
  }
  .lenderBannerSec {
    padding: 0px 10px;
  }
  .lenderWrapper {
    font-size: 13px;
    p{
      display: flex;
      flex-direction: column;
      span {
        align-self: flex-end;
      }
    }
  }
  .loanLogSec {
    //padding: 20px;
    padding: 0px;
  }
  .bgThemeColor{
    background-color: $default-white;
  }
  .container {
    width: 94%;
    margin: 20px auto;
  }
  .textWrapper {
    padding: 10px;
  }
  .loanLogSec {
    .MuiTypography-body1 { 
      font-size: 12px;
      line-height: 13px;
      letter-spacing: 0.5px;
      color: #242F3E;
    }
    .MuiFormGroup-row {
      flex-direction: column;
    }
  }
  .umHeader {
    margin-right: 0px;
  }
  .otpSec {
    width: 100%;
   .MuiFormControl-root {
        width: 38px !important;
    }
  }
  .enigmaCard {
    padding: 10px;
    margin-top: 0px;
  }
  .last_update_title {
    h2 {
        font-size: 14px;
        line-height: 42px;
    }
  }
  .policy_detail_sec {
    p {
        font-size: 14px;
        line-height: 32px;
    }
  }
  .privacy_policy_info {
    h2 {
        font-size: 18px;
        line-height: 32px;
    }
    p {
        font-size: 14px;
        line-height: 32px;
    }
  }
  .loanLogTables {
    .MuiTableCell-head {
      white-space: nowrap;
    }
  }
  //mob merchant table action popup
  .actionPopUp {
    .MuiDialogTitle-root {
        padding: 20px;
    }
    .MuiDialogActions-root {
        padding: 20px;
    }
    .MuiTypography-h6 {
        font-size: 18px;
    }
    .MuiButton-outlinedPrimary,
    .MuiButton-containedPrimary {
        font-size: 14px;
    }
  }
}

.noUsersText {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 0.7px;
  color: #242F3E; 
  opacity: 0.8;
  font-family: $plusJakartaSans-Light;
  padding: 40px;
}