.MuiTableCell-head {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5rem;
}
.MuiTableCell-body {
  font-size: 12px;
  line-height: 1.5rem;
}

.MuiTableCell-root {
  display: table-cell;
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}
